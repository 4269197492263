import React from "react";
import Search from "../components/Search";
import Layout from "../components/Layout";
import Seo from "src/utils/seo";

const SearchPage = ({ location }) => {
  const searchIndices = [{ name: `Pages`, title: `Pages` }];
  return (
    <Layout>
      <Seo pageName={"CX Insights Hub"} />
      <Search indices={searchIndices} location={location} />
    </Layout>
  );
};

export default SearchPage;
